/* @import url('https://fonts.googleapis.com/css2?family=family=Space+Grotesk:wght@400;500&display=swap'); */

.node-button text,
.patch-tab text {
    alignment-baseline: hanging;
    dominant-baseline: hanging;
}

.node-title text {
    alignment-baseline: middle;
    dominant-baseline: middle;
    text-transform: uppercase;
}

.node-title-focus.debug {
    stroke: red;
    stroke-width: 2px;
}


.node-vert .dir-outside .inlet text {
    text-anchor: end;
}

.node-vert .dir-inside .inlet text {
    text-anchor: start;
}

.node-vert .dir-between .inlet text {
    text-anchor: start;
}

.node-vert .dir-outside .outlet text {
    text-anchor: start;
}

.node-vert .dir-inside .outlet text {
    text-anchor: end;
}

.node-vert .dir-between .outlet text {
    text-anchor: end;
}

.node-vert .inlet text,
.node-vert .outlet text {
    alignment-baseline: middle;
    dominant-baseline: middle;
}

.slot-focus {
    cursor: pointer;
    pointer-events : all;
}

.slot-focus.debug {
    stroke: red;
    stroke-width: 2px;
}

.node-title-focus {
    cursor: pointer;
}

.patch-tab {
    cursor: pointer;
}

.node-button {
    cursor: pointer;
}

.remove-button circle {
    fill: rgba(48, 48, 48, 0.0);
}

.remove-button line {
    stroke: rgba(255, 255, 255, 0.0);
}

.remove-button:hover circle {
    fill: rgba(48, 48, 48, 0.3);
}

.remove-button:hover line {
    stroke: rgba(255, 255, 255, 1.0);
}

.remove-button {
    cursor: pointer;
}