body {
    padding: 0;
    margin: 0;
}

#noodle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

text.debug {
    alignment-baseline: hanging;
    dominant-baseline: hanging;
}

.no-select {
    /*cursor: default;*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}